.form-search {
  width: 100%;
  background: $blue-dark;
  padding: 20px 0;

  .loader {
    display: none;
    position: absolute;
    top: 5px;
    right: 125px;
    z-index: 10;

    path {
      fill: #f9104f;
    }
  }
  .loader--show {
    display: block;
  }

  form {
    width: auto;
    max-width: 750px;

    fieldset {
      background: #fff;
      padding: 10px;

      .input-group {
        .input-group-addon {
          border-radius: 0;
          background: none;
          border: 0;
        }
      }

      .form-control {
        border: 0;
      }

      position: relative;

      .search-result {
        position: absolute;
        background: #f9f6ed;
        left: 0;
        right: 0;
        top: 70px;
        z-index: 1001;
        display: none;
        max-height: 315px;
        overflow-y: scroll;
        box-shadow: 0 3px 10px rgba(0, 0, 0, .3);

        &.show { display: block; }

        ul {
          list-style: none;
          padding-left: 0;
          margin-bottom: 0;
        }

        > ul {
          .title {
            display: block;
            padding: 9px 0 9px 20px;
            font-weight: bold;
            color: #fff;
            font-size: 18px;
            background: #0a5773;

            > i {
              position: relative;
              top: 1px;
              margin-right: 5px;
            }
          }

          .names {
            > li {
              cursor: pointer;
              margin: 0;
              padding: 10px 0 10px 50px;
              border-bottom: 1px solid #e1ded9;

              &:hover {
                color: #fff;
                background: #f9104f;
              }

              &:last-child {
                border: none;
                padding-bottom: 11px;
              }
            }
          }
        }
      }
    }
  }
}
