// Boostrap - Colors
//
// Grayscale and brand colors for use across Bootstrap.
$gray-light:          #9e9e9e;
$gray-lighter:        #ccc;
$gray:                #7a7a7a;
$gray-dark:           #333;

$brand-primary:       #fb004a;
$brand-primary-hover: lighten($brand-primary, 30%);
$brand-secondary:     #EF8C48;

// Boostrap - Body
//
// Settings for the `<body>` element.

$body-color:        #747474;

// Links
//
// Style anchor elements.

$black: #000;
$white: #FFF;

$link-color:                 $black;
$link-hover-decoration:      underline;

// Boostrap - Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-base:  "source_sans_proregular";
$headings-color:    $black;
$text-muted:        $gray-lighter;

// Boostrap - Breadcrumbs

$breadcrumb-active-color:        $brand-primary;
$breadcrumb-divider:             ">";

// Boostrap - Pagination

$pagination-color:               $white;
$pagination-bg:                  $gray-light;
$pagination-border-color:        $gray-light;
$pagination-hover-color:         $white;
$pagination-hover-bg:            $gray;
$pagination-hover-border:        $gray;
$pagination-active-color:        $gray-dark;
$pagination-active-bg:           #efefef;
$pagination-active-border:       $gray-light;

// Boostrap - Forms

$input-color-placeholder:        #666;
$form-group-margin-bottom:       30px;

// Boostrap - Modals

$modal-inner-padding:            60px;
$modal-title-padding:            30px 30px 0 30px;

// Bootstrap - Core variables
@import "node_modules/bootstrap/scss/variables";

$fa-font-path: '../fonts';

// Avis etudiants

$blue-lighter:  #3AC2F3;
$blue-light:    #005773;
$blue-dark:     #004055;
$beige:         #f8f5ee;

$input-border-color-bold:             #efe5cf;
$input-border-focus-bold:             #e0d1b1;

$modal-notification-title-padding:    30px;

// Image filters
$image-residence-small: (
    'name': 'residence-small',
    'width': 300px,
    'height': 300px
);

$image-residence-medium: (
    'name': 'residence-medium',
    'width': 540px,
    'height': 300px
);

$image-residence-large: (
    'name': 'residence-large',
    'width': 690px,
    'height': 320px
);

$image-review-small: (
    'name': 'review-small',
    'width': 150px,
    'height': 150px
);

$available-filters: (
    $image-residence-small,
    $image-residence-medium,
    $image-residence-large,
    $image-review-small
);

// Form colors
$color-error: #d04740;
$color-success: #008715;

@import 'services';