.modal-header-success { background: $brand-success; }
.modal-header-error { background: $brand-danger; }
.modal-header-warning { background: $brand-warning; }

.modal {
    top: 50px;

    &-link {
        color: $brand-primary;
        transition: all .2s ease;
    }

    &-notification {
        text-align: center;

        .modal-header-success,
        .modal-header-error,
        .modal-header-warning {
            color: #FFFFFF;
            padding: $modal-notification-title-padding;

            i { font-size: 50px; }

            .close {
                color: #FFFFFF;
                opacity: .5;
             }
        }

        .modal-body {
            font-size: 20px;

            .modal-title {
                font-size: 30px;
                margin-bottom: 30px;
            }
        }
    }

    &-default {
        .modal-header {
            border-bottom: 0;
            text-align: center;
        }

        .modal-body {
            padding: 30px 60px 40px 60px;
            text-align: center;
        }
    }
}


