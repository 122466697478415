.form-centered {
  width: 400px;
  margin: auto;
}

.form-inscription,
.form-connection {
  padding: 40px 0;

  h2 {
    margin-bottom: 30px;
  }

  .social {
    ul {
      > li {
        > a {
          display: block;
          background: $blue-dark;
          border-radius: 50%;
          width: 80px;
          height: 80px;
          padding-top: 25px;
          text-decoration: none;
          text-align: center;

          > i {
            color: #ffffff;
            font-size: 28px;
          }
        }
      }
    }
  }

  form {
    .form-group {
      .checkbox {
        display: inline-block;
      }

      .link-password {
        color: $headings-color;
        margin-left: 15px;
      }
    }
  }
}

.notification {
  .notification-header-success {
    background: $brand-success;
  }

  .notification-header-error {
    background: $brand-danger;
  }

  .notification-header-warning {
    background: $brand-warning;
  }

  .notification-header-success,
  .notification-header-error,
  .notification-header-warning {
    margin-bottom: 40px;
    padding: 30px;
    text-align: center;
    width: 100%;

    i {
      color: #ffffff;
      font-size: 60px;
    }
  }

  .notification-body {
    text-align: center;

    h2 {
      margin-bottom: 30px;
    }

    p {
      font-size: 18px;
    }
  }
}
