$pages-legal-font-size: .875rem;

.page {
    padding-bottom: 4em;

    &-intro {
        font-style: italic;
        font-weight: bold;
    }

    &-subtitle {
        text-decoration: underline;
        font-size: 1.4rem;
        margin-top: 1em;
    }

    &-inline-title {
        text-decoration: underline;
        font-weight: bold;
    }

    &-ordered-list-title {
        position: relative;

        &::before {
            position: relative;
        }

        &:nth-of-type(1) {
            &::before {
                content: 'a) ';
            }
        }

        &:nth-of-type(2) {
            &::before {
                content: 'b) ';
            }
        }

        &:nth-of-type(3) {
            &::before {
                content: 'c) ';
            }
        }
    }

    dl {
        ul {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    dt,
    dd {
        display: inline;
    }

    dt {
        &::before {
            content: '« ';
        }

        &::after {
            content: ' » : ';
        }

        &::before,
        &::after {
            font-weight: normal;
        }
    }

    dd {
        &::after {
            display: block;
            content: ' ';
            margin: .4em 0;
        }
    }

    &--legal {
        font-size: $pages-legal-font-size;

        .page {
            &-title {
                font-size: 1.25rem;
            }

            &-subtitle {
                font-size: 1rem;
                color: $blue-lighter;
                text-decoration: none;
                text-transform: uppercase;
                margin-top: 2rem;
            }

            &-subsection-title {
                color: $brand-secondary;
                font-size: 1rem;
            }

            &-ordered-list-title {
                font-size: $pages-legal-font-size;
                text-decoration: underline;
            }

            &-list-subtitle {
                font-size: $pages-legal-font-size;
            }

            &-title-addedum {
                text-decoration: none;
                font-weight: normal;
            }

            &-paragraph {
                font-size: $pages-legal-font-size;
            }
        }

        li {
            margin-bottom: .5rem;
        }
    }
}
