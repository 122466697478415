.pagination {
    margin-top: 40px;

    .page-item {
        .page-link {
            margin-left: 8px;
            line-height: 18px;

            &:first-child {
                border-bottom-right-radius: 0;
                border-top-right-radius: 0;
            }
            &:last-child {
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
            }
        }
    }
}
