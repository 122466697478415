.form-style--border {
  .form-control {
    border-radius: 0;
    border: solid 8px $input-border-color-bold;
    margin-bottom: 10px;
    padding: 6px 12px;

    &:focus {
      border-color: $input-border-focus-bold;
    }
  }
  .c-select {
    border: solid 8px $input-border-color-bold;
    display: block;
    width: 100%;
  }
}

.form-control {
  border-radius: 0;
  margin-bottom: 20px;
  padding: 12px 18px;

  &::placeholder {
    color: #c8cdd2;
  }
}

label.required {
  &::after {
    content: '*';
    color: red;
  }
}

.form-errors {
  clear: both;
  float: none;
}

.c-input {
  > input {
    &:checked ~ .c-indicator {
      background-color: $brand-primary;
      border: $gray-lighter;
    }

    &:focus ~ .c-indicator {
      box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem $brand-primary;
      border: $gray-lighter;
    }

    &:active ~ .c-indicator {
      background-color: $brand-primary;
      border: $gray-lighter;
    }
  }
}

.c-indicator {
  top: 3px;
  background-color: #FFFFFF;
  border: solid 1px $gray-lighter;
}

.radio,
.checkbox {
  label {
    padding-left: 2.25rem;

    &::after {
      content: '';
    }
  }
}
