$author-image-width: 60px !default;
$informations-horizontal-spacing: 20px !default;
$informations-vertical-spacing: 5px !default;
$section-vertical-spacing: 1rem !default;

@mixin review-information() {
    display: inline-block;
    margin-bottom: $informations-vertical-spacing;
    margin-right: $informations-horizontal-spacing;
}

.review {
    border-bottom: 1px solid $brand-primary;
    margin-bottom: 20px;

    &:last-of-type {
        border-bottom: 0;
    }

    &-heading {
        margin-bottom: $informations-horizontal-spacing;
    }

    &-image {
        &--author {
            height: auto;
            max-width: $author-image-width;
            margin-right: $informations-horizontal-spacing;
            float: left;
        }
    }

    &-is-certified {
        padding-left: #{$informations-horizontal-spacing / 4};
        color: $brand-primary;
    }

    &-author {
        @include review-information();
        font-family: 'source_sans_prosemibold', serif;
        font-size: 1.1rem;
    }

    &-date {
        @include review-information();
        font-size: .81rem;
    }

    .rating {
        margin-bottom: $informations-vertical-spacing;
    }

    &-title {
        font-size: 1.1rem;
    }

    &-body {
        padding-left: #{$author-image-width + $informations-horizontal-spacing};
        margin-bottom: $section-vertical-spacing;
        word-break: break-all;
    }

    &-residence {
        &-address {
            font-size: .875rem;
        }
    }

    &--reply {
        background-color: $beige;
        border-bottom: 0;
        margin-bottom: 0;
        padding-left: #{$author-image-width + $informations-horizontal-spacing};
        padding-top: $informations-vertical-spacing;

        .review-author {
            color: $black;
        }
    }

    &--with-residence {
        margin-bottom: 5px;
    }

    &--show {
        display: block;
    }
}
