* {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  outline: none;
}

body {
  padding-top: 100px;
}

// Header

.header {
  width: 100%;
  background: rgba($white, .95);
  padding-top: 1rem;
  position: fixed;
  top: 0;
  z-index: 10;
}

// Mini container page résidence + connexion/inscription
.container-lg {
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}

.footer {
  width: 100%;
  background: $blue-dark;
  padding: 30px 0 20px 0;

  li {
    list-style: none;
  }

  a {
    color: $white;
  }
}

.separator {
  position: relative;
  border-top: solid 1px $gray-light;
  margin: 40px 0;

  strong {
    position: absolute;
    top: -14px;
    left: 50%;
    margin-left: -20px;
    background: $white;
    font-size: 18px;
    padding: 0 10px;

  }
}
