.search-results {
  @include section-fluid;
  .results-list {
    h2 {
      margin-bottom: 40px;
    }

    .residence-list {
      &:nth-child(2n+1) {
        clear: both;
      }

      &-image {
        border: solid 8px $white;
        display: inline-block;
        margin-bottom: 20px;
        max-height: 300px;
        text-align: center;
        width: 100%;
      }

      address {
        margin-right: 10px;
      }

      address,
      .rating {
        display: inline-block;
      }
    }
  }
}
