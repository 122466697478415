@font-face {
  font-family: 'source_sans_proregular';
  src: url('../../fonts/sourcesanspro-regular-webfont.woff2') format('woff2'),
     url('../../fonts/sourcesanspro-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'source_sans_prosemibold';
  src: url('../../fonts/sourcesanspro-semibold-webfont.woff2') format('woff2'),
     url('../../fonts/sourcesanspro-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'source_sans_problack';
  src: url('../../fonts/sourcesanspro-black-webfont.woff2') format('woff2'),
     url('../../fonts/sourcesanspro-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'source_sans_problack_italic';
  src: url('../../fonts/sourcesanspro-blackitalic-webfont.woff2') format('woff2'),
     url('../../fonts/sourcesanspro-blackitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'source_sans_probold';
  src: url('../../fonts/sourcesanspro-bold-webfont.woff2') format('woff2'),
     url('../../fonts/sourcesanspro-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'source_sans_probold_italic';
  src: url('../../fonts/sourcesanspro-bolditalic-webfont.woff2') format('woff2'),
     url('../../fonts/sourcesanspro-bolditalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'source_sans_proextralight';
  src: url('../../fonts/sourcesanspro-extralight-webfont.woff2') format('woff2'),
     url('../../fonts/sourcesanspro-extralight-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'source_sans_proXLtIt';
  src: url('../../fonts/sourcesanspro-extralightitalic-webfont.woff2') format('woff2'),
     url('../../fonts/sourcesanspro-extralightitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'source_sans_proitalic';
  src: url('../../fonts/sourcesanspro-italic-webfont.woff2') format('woff2'),
     url('../../fonts/sourcesanspro-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'source_sans_prolight';
  src: url('../../fonts/sourcesanspro-light-webfont.woff2') format('woff2'),
     url('../../fonts/sourcesanspro-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'source_sans_prolight_italic';
  src: url('../../fonts/sourcesanspro-lightitalic-webfont.woff2') format('woff2'),
     url('../../fonts/sourcesanspro-lightitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'source_sans_proSBdIt';
  src: url('../../fonts/sourcesanspro-semibolditalic-webfont.woff2') format('woff2'),
     url('../../fonts/sourcesanspro-semibolditalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
