@import "node_modules/slick-carousel/slick/slick.scss";
@import "node_modules/slick-lightbox/src/styles/slick-lightbox.sass";

// Fix 1px issue. See: https://github.com/kenwheeler/slick/issues/771
.slick-slide {
  height: auto;
}

.carousel {
  @include make-container();
  @include make-container-max-widths((
    md: map-get($container-max-widths, 'md')
  ));
  width: 100%;
  position: relative;
}

.carousel-arrow,
.slick-arrow {
  &.carousel-arrow-prev {
    left: 1rem;
  }
  &.carousel-arrow-next {
    right: 1rem;
  }
}

.carousel-arrow,
.slick-arrow {
  text-shadow: 0 1px 2px rgba(0,0,0,0.6);
  background: none;
  opacity: .5;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  border: 0;

  font-size: 1px;
  letter-spacing: -1px;
  color: transparent;

  &::before,
  .icon-prev::before,
  .icon-next::before {
    @include icon-properties();
    font-size: 2.5rem;
    letter-spacing: normal;
    color: $white;
  }

  &:hover {
    opacity: 1;
  }

  &:focus {
    outline: none;
  }

  .icon-prev,
  &.slick-prev {
    &::before {
      content: "\e912";
    }
  }

  .icon-next,
  &.slick-next {
    &::before {
      content: "\e913";
    }
  }
}

.carousel-dots {
  position: static;
  z-index: 1;
  padding: 0;
  width: 100%;
  height: 2.5rem;
  line-height: 2.5rem;

  .slick-dots {
    text-align: center;
    list-style: none;
    margin: 10px 0 0;
    padding: 0;

    li {
      display: inline-block;
      margin: 0 .25rem;
      cursor: pointer;
      position: relative;
      width: 15px;
      height: 15px;

      &.slick-active {
        > button {
          border-color: $white;

          &::after {
            height: 0;
          }
        }
      }

      > button {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 120%;
        outline: 0;
        text-indent: -9999px;
        border: 2px $white solid;
        border-radius: 50%;
        background: none;
        background-color: rgba($brand-primary, .75);
        transition: background .3s;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: $white;
          transition: height .3s;
        }
      }
    }
  }
}
