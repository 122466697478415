// Bootstrap - Variables
$breadcrumb-bg:                 transparent;
$breadcrumb-padding-vertical:   1rem;
$breadcrumb-padding-horizontal: 0;

// Bootstrap - Components
@import "node_modules/bootstrap/scss/breadcrumb";

.breadcrumb {
  margin-bottom: 0;

  > li {
    > a {
      color: $gray-light;
    }
  }
}
