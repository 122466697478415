/* ****** */
/* Header */
/* ****** */

.header {
  padding-top: 0.5rem;
}

/* ****** */
/* Navbar */
/* ****** */

.navbar {
  margin: 0 0 0.5rem 0;
  .navbar-brand {
    .logo {
      color: #000000;
      fill: #000000;
    }
  }
  .navbar-toggler {
    border-color: $link-color;
    span {
      background-color: $link-color;
    }
  }
  a {
    color: $link-color;
  }
}

/* ********* */
/* Jumbotron */
/* ********* */

.jumbotron {
  height: 485px;
  background: url('../../images/home/jumbotron.jpg') no-repeat top center;
}

@include media-breakpoint-up(xl) {
  .jumbotron {
    height: 550px;
  }
}

/* ******* */
/* Reviews */
/* ******* */

.reviews-map {
  @include section-fluid;
  .reviews {
    padding: 40px 0;

    h2 {
      color: $body-color;
      text-transform: uppercase;
      font-size: 36px;
      margin-bottom: 40px;
    }

    .map {
      text-align: center;
      padding: 0 20px 0 0;
      svg {
        height: auto;
        width: 100%;
      }
    }
  }
}

/* ******************* */
/* Favorite Residences */
/* ******************* */

.favorite-residences {
  @include make-container();
  @include make-container-max-widths();
  background-color: $blue-dark;
  margin-bottom: 4rem;
  margin-top: 4rem;
  padding: 2rem 5rem 5.5rem 5rem;
  position: relative;

  h3 {
    color: $white;
    font-size: 1.5rem;
    margin-bottom: 2rem;
    text-transform: uppercase;
  }

  .carousel {
    max-width: 100%;
    .carousel-arrow {
      &.carousel-arrow-prev {
        left: -5rem;
      }

      &.carousel-arrow-next {
        right: -5rem;
      }
    }

    .carousel-items {
      .card {
        border-radius: 0;
        border: solid .5rem $white;
        margin: 0 .5rem;
        //overflow: hidden;
        position: relative;
        min-height: 300px;
        width: 300px;

        .card-block {
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 5;
          width: 100%;
          min-height: 90px;
          padding: 0 10px;
          background: rgba(255, 255, 255, .7);
          transition: 0.2s ease-in-out;
          text-align: center;

          &:hover,
          &:focus {
            height: 95px;
          }

          .icon-card {
            display: inline-block;
            margin-top: -20px;
            width: 30px;
          }

          .card-title {
            color: $body-color;
            font-family: 'source_sans_prosemibold';
            font-size: 1rem;
            margin-bottom: 0;
          }

          .card-rate {
            line-height: 1rem;
            text-align: center;

            .rating {
              clear: both;
              display: inline-block;
              float: none;
              margin: 0;
              height: 20px;
              text-align: center;
              width: 94px;
            }
          }

          .card-text {
            color: #fb004a;
          }
        }
      }
    }
  }
}

/* ******* */
/* Advices */
/* ******* */

.advices {
    @include section-fluid;
    .container {
        margin-top: -40px;

        h3 {
            background: $blue-dark;
            color: $white;
            display: inline-block;
            font-size: 24px;
            margin-bottom: 30px;
            padding: 5px 30px;
            text-transform: uppercase;
        }

        h4 {
            font-size: 20px;
            > i {
                font-size: 36px;
                color: $brand-primary;
                margin-right: 10px;
            }
        }

        ul li a {
            font-size: 14px;
        }
    }
}
