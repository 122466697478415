// Bootstrap - Variables
$navbar-padding-vertical:   0;
$navbar-padding-horizontal: 0;

// Bootstrap - Components
@import "node_modules/bootstrap/scss/navbar";

// Variables
$navbar-toggler-size: 40px;
$navbar-toggler-lines-size: 22px;
$navbar-push: 250px;

body {
  left: 0;
  transition: all 0.2s ease;
  overflow-x: hidden;
  position: relative;
}

.navbar-container {
  @include make-container();
  @include make-container-max-widths();
}

.navbar {
  .navbar-toggler {
    @include pull-right;
    border: 2px solid #ffffff;
    border-radius: 4px;
    width: $navbar-toggler-size;
    height: $navbar-toggler-size;
    position: relative;
    &.active {
      span {
        &.line-2 {
          opacity: 0;
        }
        &.line-1 {
          transform: rotate(45deg);
          top: 17px;
        }
        &.line-3 {
          transform: rotate(-45deg);
          top: 17px;
        }
      }
    }
    span {
      width: $navbar-toggler-lines-size;
      height: 2px;
      position: absolute;
      background-color: #FFF;
      left: ($navbar-toggler-size - $navbar-toggler-lines-size) / 2 - 2px;
      transition: all 0.2s linear;
      &.line-1 {
        top: 11px;
      }
      &.line-2 {
        top: 17px;
      }
      &.line-3 {
        top: 23px;
      }
    }
  }
  .navbar-nav-container {
    @include pull-right;
    margin-top: 2.5rem;
  }
  a {
    color: #ffffff;
  }
}

@include media-breakpoint-up(md) {
  .navbar {
    .navbar-toggler {
      display: none;
    }
    .navbar-nav-container {
      .navbar-brand {
        display: none;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  body {
    &.push {
      left: -$navbar-push;
    }
  }
  .navbar {
    .navbar-nav-container {
      width: $navbar-push;
      height: 100%;
      position: fixed;
      z-index: 3000;
      top: 0;
      right: -$navbar-push;
      margin: 0;
      overflow-y: scroll;
      transition: all 0.2s ease;
      &.push {
        right: 0;
      }
      .nav-item {
        float: none;
        margin: 0;
        border-top: 1px solid rgba(116,116,116,0.2);
        &:first-child {
          border-top: 0;
        }
        .nav-link {
          padding: 10px 15px;
          color: $link-color;
          transition: all 0.1s linear;
          &:hover {
            color: #FFFFFF;
            background: $brand-primary;
          }
        }
      }
    }
  }
}
