@media (max-width: 768px - 1) {

  // Home

  .favorite-residency-slider {
    h3 {
      padding-left: 0;
    }

    .carousel-inner {
      padding: 0;
    }
  }

  .advices {
    text-align: center;

    .row {
      text-align: left;
    }
  }

  // Search

  .form-search {
    form {
      fieldset {
        padding: 0;

        .input-group-addon {
          padding-right: 0;
        }

        .form-control {
          padding: 15px 18px 12px 5px;
        }

        .input-group-btn {
          .btn {
            margin-right: -1px;
            padding: 10px 10px 12px 10px;
          }

          .icon-search {
            font-size: 22px;
          }
        }
      }
    }
  }

  // Rating

  .rating {
    font-size: 20px;

    .rating-label {
      font-size: 20px;
    }
  }

  .global-rate-col .rating-label {
    font-size: 26px;
  }

  // Residence

  .residence-header {
    .residence-info {
      text-align: center;

      .residence-info-photo {
        margin: 0 auto;
      }

      h2 {
        margin: 20px 0;
      }

      .rating {
        margin-bottom: 20px;
        float: none;
      }
    }
  }

  .residence-title {
    display: flex;
    flex-direction: column;

    h2 {
      order: -1;
    }
  }

  .residence-equipment {
    padding-bottom: 10px;

    a {
      color: $body-color;
      text-decoration: none;

      > h3 > i {
        margin-left: 15px;
      }
    }

    .equipment {
      i, p {
        height: 35px;
      }
    }
  }

  .btn-action .btn {
    width: 100%;
    margin-bottom: 15px;
  }

  .form-inscription, .form-connection {
    form {
      .form-group {
        .checkbox {
          display: block;
        }
      }
    }
  }

  .favorite-residences {
    .carousel {
      .carousel-items {
        .card {
          margin: 0;
        }
      }
    }
  }

  // Review

  .review-informations {
    .rating {
      float: none;
      margin-top: 5px;
    }
  }

  .review-title {
    float: left;
    font-size: 20px;
    line-height: 1.5;
    margin-top: 5px;
  }

  .review-body {
    clear: both;
  }

  // User

  .form-centered {
    width: 100%;
    margin: auto;
  }

  .form-inscription,
  .form-connection {
    padding: 40px;
  }
}
