.btn {
    border-radius: 0;
    font-family: 'source_sans_prosemibold';
    padding: .75rem 1rem;

    &.btn-dual {
        padding: .25rem 1rem;
        line-height: 1.25rem;
    }
}

.link-black { color: $headings-color; }
