.block-rating {
  line-height: 1.5rem;
  min-height: 30px;
}

.rating {
  min-width: 94px;
  float: right;

  input[type="radio"] {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  .rating-count {
    display: inline-block;
    float: right;
  }

  .rating-label {
    color: $gray-lighter;
    font-size: 14px;
    float: right;
    margin: 0;
    margin-right: 4px;
    text-decoration: none;
    transition: color .2s;
    display: inline-block;

    &::before {
      font-family: 'FontAwesome';
      display: inline-block;
      content: $fa-var-star;
    }

    &-half {
      &::before {
        content: $fa-var-star-half;
        position: absolute;
        margin-left: 4px;
      }
    }

    &-active {
      &::before {
        color: $brand-primary;
      }
    }

    &-text {
      display: none;
    }
  }

  & > input:checked ~ label, /* show $brand-primary star when clicked */
  &:not(:checked) > label:hover, /* hover current star */
  &:not(:checked) > label:hover ~ label {
    color: $brand-primary;
  } /* hover previous stars in list */

  & > input:checked + label:hover, /* hover current star when changing rating */
  & > input:checked ~ label:hover,
  & > label:hover ~ input:checked ~ label, /* lighten current selection */
  & > input:checked ~ label:hover ~ label {
    color: $brand-primary-hover;
  }
}

.global-rate-col {
  &-container {
    margin: 0;
    margin-bottom: 30px;
    border-bottom: 1px solid $beige;

    [class^="col-"],
    [class*=" col-"] {
      h4 {
        margin-bottom: 0;
      }

      padding-left: 0;
      padding-right: 0;
    }
  }

  .rating {
    &-label {
      font-size: 24px;
    }
  }
}
