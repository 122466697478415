@font-face {
  font-family: 'icomoon';
  src:    url('../../fonts/icomoon.eot?2y0fdr');
  src:    url('../../fonts/icomoon.eot?2y0fdr#iefix') format('embedded-opentype'),
    url('../../fonts/icomoon.ttf?2y0fdr') format('truetype'),
    url('../../fonts/icomoon.woff?2y0fdr') format('woff'),
    url('../../fonts/icomoon.svg?2y0fdr#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin icon-properties() {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"],
[class*=" icon-"] {
  @include icon-properties();
}

.icon-dropdown-stroke:before {
  content: "\e900";
}
.icon-checked-box:before {
  content: "\e902";
}
.icon-localisation:before {
  content: "\e90f";
}
.icon-before:before {
  content: "\e906";
}
.icon-dropdown:before {
  content: "\e907";
}
.icon-prev:before {
  content: "\e912";
}
.icon-next:before {
  content: "\e913";
}
.icon-residence:before {
  content: "\e917";
}
.icon-star:before {
  content: "\e919";
}
.icon-search:before {
  content: "\e91b";
}
.icon-google-plus:before {
  content: "\ea8b";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-twitter:before {
  content: "\ea96";
}

// Home
.icon-aides-logement:before {
    content: "\e904";
}
.icon-ass-habitation:before {
    content: "\e905";
}
.icon-colocation:before {
    content: "\e908";
}
.icon-log-alternatif:before {
    content: "\e910";
}
.icon-prendre-un-appart:before {
    content: "\e915";
}
