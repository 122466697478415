// Variables and mixins
@import "base/variables";
@import "base/mixins";

// Bootstrap - Reset and dependencies
@import "node_modules/bootstrap/scss/normalize";
@import "node_modules/bootstrap/scss/print";

// Bootstrap - Core CSS
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/tables";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/buttons";

// Bootstrap - Components
@import "node_modules/bootstrap/scss/animation";
@import "node_modules/bootstrap/scss/dropdown";
@import "node_modules/bootstrap/scss/button-group";
@import "node_modules/bootstrap/scss/input-group";
@import "node_modules/bootstrap/scss/custom-forms";
@import "node_modules/bootstrap/scss/nav";
@import "node_modules/bootstrap/scss/card";
@import "node_modules/bootstrap/scss/pagination";
@import "node_modules/bootstrap/scss/pager";
@import "node_modules/bootstrap/scss/labels";
@import "node_modules/bootstrap/scss/alert";
@import "node_modules/bootstrap/scss/list-group";
@import "node_modules/bootstrap/scss/responsive-embed";
@import "node_modules/bootstrap/scss/close";

// Bootstrap - Components / JavaScript
@import "node_modules/bootstrap/scss/modal";

// Bootstrap - Utility classes
@import "node_modules/bootstrap/scss/utilities";
@import "node_modules/bootstrap/scss/utilities-spacing";
@import "node_modules/bootstrap/scss/utilities-responsive";

// Font Awesome
@import "node_modules/font-awesome/scss/font-awesome";

// Base
@import "base/fonts";
@import "base/icons";
@import "base/layout";

// Generic
@import "generic/buttons";
@import "generic/form";
@import "generic/images";
@import "generic/modals";
@import "generic/pagination";
@import "generic/ranking";

// Components
@import "components/logo";
@import "components/navbar";
@import "components/breadcrumb";
@import "components/search";
@import "components/review";
@import "components/carousel";
@import "components/jumbotron";

// Pages
@import "pages/results";
@import "pages/user";
@import "pages/home";
@import "pages/residence";
@import "pages/pages";

// Responsive
@import "responsive/phone";

// Vue.js
[v-cloak] {
  display: none;
}
