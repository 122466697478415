// Bootstrap - Variables
$jumbotron-padding: 0;

// Bootstrap - Components
@import "node_modules/bootstrap/scss/jumbotron";

.jumbotron {
  position: relative;

  .baseline {
    @include make-container();
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 2rem 0 0 0;
    background-color: rgba(85, 53, 32, 0.4);
    text-align: center;

    h1 {
      color: #FFFFFF;
      font-family: 'source_sans_problack';
      font-size: 2.25rem;
      text-transform: uppercase;
    }

    p {
      color: #FFFFFF;
      font-family: 'source_sans_prosemibold';
      font-size: 1.25rem;
    }
  }
}

@include media-breakpoint-up(md) {
  .jumbotron {
    .baseline {
      h1 {
        font-size: 2.75rem;
      }
      p {
        font-size: 1.5rem;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .jumbotron {
    .baseline {
      h1 {
        font-size: 3.75rem;
      }
    }
  }
}
