@font-face {
  font-family: 'questrial_regular';
  src:    url('../../fonts/questrial-regular-webfont.eot?2y0fdr');
  src:    url('../../fonts/questrial-regular-webfont.eot?2y0fdr#iefix') format('embedded-opentype'),
    url('../../fonts/questrial-regular-webfont.ttf?2y0fdr') format('truetype'),
    url('../../fonts/questrial-regular-webfont.woff?2y0fdr') format('woff'),
    url('../../fonts/questrial-regular-webfont.svg?2y0fdr#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

.logo {
  color: #ffffff;
  display: inline-block;
  fill: #ffffff;
  font-size: 0.6rem;
  margin: 0;
  max-width: 230px;

  .brand {
    fill: inherit;
  }

  .figure-caption {
    font-family: 'questrial_regular';
    padding-left: 25%;
    font-size: 1em;
    line-height: 0.8em;
    color: inherit;
  }
}
