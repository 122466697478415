.image {
    width: 100%;
    height: auto;

    @each $filter in $available-filters {
        &--#{map-get($filter, 'name')} {
            @if map-has-key($filter, 'width') {
                max-width: #{map-get($filter, 'width')};
            }

            @if map-has-key($filter, 'height') {
                max-height: #{map-get($filter, 'height')};
            }
        }
    }
}
