// Bootstrap - Core CSS
@import "node_modules/bootstrap/scss/images";


/* **************** */
/* Residence Header */
/* **************** */

.residence-header {
  @include section-fluid;

  .residence-info {
    .residence-info-photo {
      @extend .img-circle;
      height: 100%;
      max-height: 185px;
      max-width: 185px;
      overflow: hidden;
      text-align: center;

      .image {
        width: auto;
      }
    }

    .residence-name {
      border-bottom: 1px solid $brand-primary;
    }

    address {
      margin-bottom: .5em;
    }

    .rating {
      margin-bottom: 20px;
      line-height: 2em;
      float: right;
    }
  }
}

/* ********************* */
/* Residence Description */
/* ********************* */

.residence-description {
  @include make-container();
  @include make-container-max-widths((
      md: map-get($container-max-widths, 'md')
  ));
  padding-top: 40px;
  padding-bottom: 20px;

  h3 {
    font-family: 'source_sans_prosemibold';
    font-size: 24px;
    color: $body-color;
    margin-bottom: 20px;
  }

  .residence-services {
    padding-top: 20px;

    &-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 0;
    }

    .residence-service {
      width: 25%;
      list-style: none;
      padding: 0;
      margin: .5rem 0 0 0;

      i {
        margin: 5px 5px 0 0;
        float: left;
        height: 50px;

        @each $service in $services {
          &.#{map-get($service, 'name')} {
            &::before {
              background: url("../../images/residence/services/#{map-get($service, 'src')}") no-repeat center;
              background-size: contain;
              width: 45px;
              height: 20px;
              content: ' ';
              display: inline-block;
            }
          }
        }
      }

      p {
        min-height: 20px;
        vertical-align: middle;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .residence-description {
    .residence-services {
      .residence-service {
        width: 50%;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .residence-description {
    .residence-services {
      &-list {
        flex-direction: column;
      }

      .residence-service {
        width: 100%;
      }
    }
  }
}

/* **************** */
/* Residence Photos */
/* **************** */

.residence-photos {
  @include section-fluid;
  padding-bottom: 0;
}

.residence-photo {
  width: 100%;

  &.image {
    display: inline-block;
  }

  &-link {
    display: inline-block;
    // width: 100%;
    text-align: center;
  }
}

@include media-breakpoint-down(sm) {
  .residence-photos {
    padding: 0;
    .carousel {
      padding: 0;
    }
  }
}

/* ***************** */
/* Residence Reviews */
/* ***************** */

.residence-reviews {
  @include make-container();
  @include make-container-max-widths((
      md: map-get($container-max-widths, 'md')
  ));

  padding-top: 40px;
}

@include media-breakpoint-down(sm) {
  .residence-reviews {
    padding-top: 5px;
  }
}

/* ********************* */
/* Residence Review Form */
/* ********************* */

.residence-review-form {
  padding: 40px 0;
  width: 100%;
  box-shadow: 0 0 40px #ccc;

  h3,
  h4 {
    color: $body-color;
    font-family: 'source_sans_prosemibold';
    margin-bottom: 30px;
  }

  h3 {
    font-size: 30px;
  }

  h4 {
    font-size: 24px;
  }

  .form-style--border {
    .form-label {
      font-family: 'source_sans_prosemibold';
      font-size: 24px;
      margin-bottom: 15px;

      &.has-helper {
        &::after {
          content: $fa-var-question-circle;
          cursor: pointer;
          display: inline-block;
          font-family: 'FontAwesome';
          font-size: .8em;
          margin-left: .5em;
        }
      }
    }

    .c-input, .c-radio {
      font-family: $font-family-base;
      font-size: 18px;
    }

    .validate {
      padding: 30px;
      background: #efefef;
      .btn { width: 145px; }
    }
  }

  .report {
    padding-left: 20px;
    margin-bottom: 30px;
    border-left: solid 8px #efefef;

    h4 { margin-bottom: 20px; }

    .block-rating {
      > label, > p {
        display: inline-block;
        font-size: 18px;
        margin-left: 3px;
        margin-bottom: 0;
        vertical-align: top;
      }

      > .rating { display: inline-block; }
    }

    &.sub {
      margin-left: 30px;
    }
  }
}

.btn-action {
  .btn {
    margin-right: .5rem;
    &:last-child { margin-right: 0; }
    small {
      font-size: 75%;
    }
  }
}
